import React, { useState, useEffect } from "react";
import Header from "./Header";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const LevelProgressionCard = ({
  category,
  levels,
  isSelected,
  onToggleSelect,
}) => {
  return (
    <div
      className={`border p-4 rounded-lg mb-4 cursor-pointer transition-all ${
        isSelected
          ? "bg-gradient-to-r from-purple-600 to-indigo-600 border-white"
          : "bg-gray-800 border-gray-700 hover:border-gray-500"
      }`}
      onClick={onToggleSelect}
    >
      <h3 className="text-xl font-bold text-white mb-2 capitalize">
        {category}
      </h3>
      <div className="space-y-2">
        {levels.map((level) => (
          <div
            key={level.level_reached}
            className="bg-black bg-opacity-20 p-2 rounded"
          >
            <p className="text-white">
              Level {level.level_reached + 1} achieved
              <span className="text-sm text-gray-300 ml-2">
                ({level.projects_at_level} projects completed)
              </span>
            </p>
            <p className="text-sm text-gray-300">
              {new Date(level.achieved_at).toLocaleDateString()}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

const ProjectCard = ({ project, isSelected, onToggleSelect }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleClick = (e) => {
    if (e.target.tagName !== "BUTTON") {
      onToggleSelect();
    }
  };

  return (
    <div
      className={`border p-4 rounded-lg mb-4 cursor-pointer transition-all ${
        isSelected
          ? "bg-gradient-to-r from-purple-600 to-indigo-600 border-white"
          : "bg-gray-800 border-gray-700 hover:border-gray-500"
      }`}
      onClick={handleClick}
    >
      <div className="flex justify-between items-start">
        <h3 className="text-lg font-bold text-white mb-2">{project.title}</h3>
        <span className="text-sm text-gray-300 capitalize">
          {project.category}
        </span>
      </div>
      <p className="text-gray-300 mb-2">{project.description}</p>

      <button
        onClick={(e) => {
          e.stopPropagation();
          setIsExpanded(!isExpanded);
        }}
        className="text-blue-400 hover:text-blue-300 text-sm mb-2"
      >
        {isExpanded ? "Show Less" : "Show More"}
      </button>

      {isExpanded && project.submission && (
        <div className="mt-2 p-4 bg-black bg-opacity-30 rounded">
          <h4 className="text-white font-bold mb-2">Project Submission:</h4>
          <p className="text-gray-300 whitespace-pre-wrap">
            {project.submission}
          </p>
        </div>
      )}

      {project.feedback && (
        <div className="mt-2">
          <p className="text-green-400 text-sm">
            <strong>Strength:</strong> {project.feedback.strength}
          </p>
          <p className="text-yellow-400 text-sm">
            <strong>Area for Growth:</strong> {project.feedback.weakness}
          </p>
        </div>
      )}
    </div>
  );
};

const Transcript = ({ user }) => {
  const [completedProjects, setCompletedProjects] = useState([]);
  const [progressions, setProgressions] = useState({});
  const [selectedProjects, setSelectedProjects] = useState(new Set());
  const [selectedProgressions, setSelectedProgressions] = useState(new Set());
  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (user?.id) {
      // Fetch completed projects
      fetch(`${BACKEND_URL}/api/get_completed_projects?userId=${user.id}`)
        .then((res) => res.json())
        .then((data) => {
          if (Array.isArray(data)) {
            setCompletedProjects(data);
          }
        })
        .catch((err) => {
          console.error("Error fetching projects:", err);
          setError("Failed to load projects");
        });

      // Fetch progression history
      fetch(`${BACKEND_URL}/api/get_progression_history?userId=${user.id}`)
        .then((res) => res.json())
        .then((data) => {
          if (Array.isArray(data)) {
            // Group progressions by category
            const grouped = data.reduce((acc, prog) => {
              if (!acc[prog.category]) {
                acc[prog.category] = [];
              }
              acc[prog.category].push(prog);
              return acc;
            }, {});
            setProgressions(grouped);
          }
        })
        .catch((err) => {
          console.error("Error fetching progressions:", err);
        });
    }
  }, [user?.id]);

  const handleProjectToggle = (projectId) => {
    setSelectedProjects((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(projectId)) {
        newSet.delete(projectId);
      } else {
        newSet.add(projectId);
      }
      return newSet;
    });
  };

  const handleProgressionToggle = (category) => {
    setSelectedProgressions((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(category)) {
        newSet.delete(category);
      } else {
        newSet.add(category);
      }
      return newSet;
    });
  };

  const handleGenerateTranscript = async () => {
    if (selectedProjects.size === 0 && selectedProgressions.size === 0) {
      alert("Please select at least one project or progression");
      return;
    }

    setIsGenerating(true);
    try {
      const response = await fetch(`${BACKEND_URL}/api/generate_transcript`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: user.id,
          projectIds: Array.from(selectedProjects),
          progressionCategories: Array.from(selectedProgressions),
        }),
      });

      const data = await response.json();
      if (data.success) {
        // PDF download
        const pdfBlob = new Blob(
          [Uint8Array.from(atob(data.pdf), (c) => c.charCodeAt(0))],
          { type: "application/pdf" }
        );
        const pdfUrl = window.URL.createObjectURL(pdfBlob);
        const pdfLink = document.createElement("a");
        pdfLink.href = pdfUrl;
        pdfLink.download = `transcript-${new Date().toISOString()}.pdf`;
        document.body.appendChild(pdfLink);
        pdfLink.click();
        window.URL.revokeObjectURL(pdfUrl);
        document.body.removeChild(pdfLink);

        alert("Transcript generated and downloaded successfully!");
      } else {
        throw new Error(data.error || "Failed to generate transcript");
      }
    } catch (error) {
      console.error("Error generating transcript:", error);
      alert("Error generating transcript. Please try again.");
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-900">
      <Header />
      <div className="container mx-auto px-4 py-8">
        {error && (
          <div className="bg-red-500 text-white p-4 rounded-lg mb-6">
            {error}
          </div>
        )}

        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-white">
            Select Content for Transcript
          </h2>
          <button
            onClick={handleGenerateTranscript}
            disabled={
              isGenerating ||
              (selectedProjects.size === 0 && selectedProgressions.size === 0)
            }
            className={`px-4 py-2 rounded-lg font-bold ${
              isGenerating ||
              (selectedProjects.size === 0 && selectedProgressions.size === 0)
                ? "bg-gray-600 text-gray-400"
                : "bg-gradient-to-r from-purple-600 to-indigo-600 text-white hover:from-purple-700 hover:to-indigo-700"
            }`}
          >
            {isGenerating ? "Generating..." : "Generate Transcript"}
          </button>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Level Progressions */}
          <div>
            <h3 className="text-xl font-bold text-white mb-4">
              Level Progressions
            </h3>
            {Object.entries(progressions).map(([category, levels]) => (
              <LevelProgressionCard
                key={category}
                category={category}
                levels={levels}
                isSelected={selectedProgressions.has(category)}
                onToggleSelect={() => handleProgressionToggle(category)}
              />
            ))}
          </div>

          {/* Completed Projects */}
          <div>
            <h3 className="text-xl font-bold text-white mb-4">
              Completed Projects
            </h3>
            {completedProjects.map((project) => (
              <ProjectCard
                key={project.id}
                project={project}
                isSelected={selectedProjects.has(project.id)}
                onToggleSelect={() => handleProjectToggle(project.id)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Transcript;
