import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import { supabase } from "./supabaseClient";
import Auth from "./components/Auth";
import Form from "./components/Form";
import SafeGraph from "./Graph";
import LandingPage from "./components/LandingPage";
import ExtensionDownload from "./components/ExtensionDownload";
import posthog from "posthog-js";
import ErrorBoundary from "./components/ErrorBoundary";
import Transcript from "./components/Transcript";
import Plans from "./components/Plans";

function AppContent() {
  const [session, setSession] = useState(null);
  const location = useLocation();

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });
  }, []);

  useEffect(() => {
    // Track page views
    posthog.capture("$pageview", {
      path: location.pathname,
      url: window.location.href,
    });
  }, [location]);

  useEffect(() => {
    if (session && session.user) {
      posthog.identify(session.user.id, {
        email: session.user.email,
        name: session.user.user_metadata?.full_name,
      });
      posthog.capture("user_logged_in");
    }
  }, [session]);

  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route
        path="/login"
        element={!session ? <Auth /> : <Navigate to="/dashboard" replace />}
      />
      <Route
        path="/dashboard"
        element={
          !session ? (
            <Navigate to="/login" replace />
          ) : (
            <Form user={session.user} />
          )
        }
      />
      <Route
        path="/graph"
        element={
          !session ? (
            <Navigate to="/login" replace />
          ) : (
            <SafeGraph user={session.user} />
          )
        }
      />
      <Route path="/extension-download" element={<ExtensionDownload />} />
      <Route
        path="/transcript"
        element={
          !session ? (
            <Navigate to="/login" replace />
          ) : (
            <Transcript user={session.user} />
          )
        }
      />
      <Route path="/plans" element={<Plans />} />
    </Routes>
  );
}

function App() {
  useEffect(() => {
    const handleError = (event) => {
      if (
        event.message ===
        "ResizeObserver loop completed with undelivered notifications."
      ) {
        event.stopImmediatePropagation();
      }
    };

    window.addEventListener("error", handleError);

    return () => {
      window.removeEventListener("error", handleError);
    };
  }, []);

  return (
    <ErrorBoundary>
      <Router>
        <AppContent />
      </Router>
    </ErrorBoundary>
  );
}

export default App;
