import React from "react";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import posthog from "posthog-js";

const PlanCard = ({ title, price, features, isPopular, onSelect }) => (
  <div
    className={`bg-gray-800 rounded-lg p-6 ${
      isPopular ? "border-2 border-indigo-500 transform scale-105" : ""
    }`}
  >
    <div className="text-center">
      <h3 className="text-xl font-bold text-white mb-2">{title}</h3>
      <div className="text-3xl font-bold text-white mb-4">
        ${price}
        <span className="text-sm text-gray-400">/month</span>
      </div>
      {isPopular && (
        <span className="bg-indigo-500 text-white px-3 py-1 rounded-full text-sm mb-4 inline-block">
          Most Popular
        </span>
      )}
    </div>
    <ul className="space-y-3 mb-6">
      {features.map((feature, index) => (
        <li key={index} className="flex items-center text-gray-300">
          <svg
            className="w-5 h-5 text-green-500 mr-2"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 13l4 4L19 7"
            />
          </svg>
          {feature}
        </li>
      ))}
    </ul>
    <button
      onClick={onSelect}
      className={`w-full py-2 px-4 rounded-lg font-bold transition duration-200 ${
        isPopular
          ? "bg-indigo-500 hover:bg-indigo-600 text-white"
          : "bg-gray-700 hover:bg-gray-600 text-white"
      }`}
    >
      Select Plan
    </button>
  </div>
);

const Plans = () => {
  const navigate = useNavigate();

  const handleSelectPlan = (planType) => {
    posthog.capture("plan_selected", { plan: planType });
    if (planType === "free") {
      navigate("/login");
    } else {
      navigate("/login", { state: { selectedPlan: "student" } });
    }
  };

  return (
    <div className="min-h-screen bg-gray-900">
      <Header />
      <div className="container mx-auto px-4 py-16">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold text-white mb-4">
            Choose Your Learning Journey
          </h2>
          <p className="text-xl text-gray-400">
            Start with our free plan or unlock unlimited potential with our
            student plan
          </p>
        </div>
        <div className="grid md:grid-cols-2 gap-8 max-w-4xl mx-auto">
          <PlanCard
            title="Free Plan"
            price="0"
            features={[
              "Try 3 Personalized Projects",
              "Basic progress tracking",
              "Community support",
              "2 Learning Branches",
              "Basic transcript generation",
            ]}
            onSelect={() => handleSelectPlan("free")}
          />
          <PlanCard
            title="Student Plan"
            price="29"
            features={[
              "Unlimited projects",
              "Advanced progress tracking",
              "Priority support",
              "Unlimited content recommendations",
              "Advanced transcript generation",
              "Custom learning paths",
              "AI-powered project feedback",
            ]}
            isPopular={true}
            onSelect={() => handleSelectPlan("student")}
          />
        </div>
      </div>
    </div>
  );
};

export default Plans;
