import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { supabase } from "../supabaseClient";

const buttonClasses =
  "text-white px-4 py-2 rounded-md text-sm font-medium transition-colors duration-200 ease-in-out whitespace-nowrap";

const Header = ({ onChangePasswordClick }) => {
  const navigate = useNavigate();
  const [session, setSession] = useState(null);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  const handleSignOut = async () => {
    try {
      await supabase.auth.signOut();
      navigate("/");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const handleExtensionDownload = () => {
    navigate("/extension-download");
  };

  return (
    <header className="bg-gray-900 shadow-lg">
      <nav className="container mx-auto px-4 h-16">
        <div className="flex justify-between items-center h-full">
          <Link to="/" className="flex items-center space-x-2">
            <img
              src="/heretic_school_logo.png"
              alt="Heretic.School"
              className="h-8 w-auto"
            />
            <span className="text-xl font-bold text-white">Heretic.School</span>
          </Link>
          <div className="flex items-center space-x-3">
            {(window.location.pathname === "/graph" ||
              window.location.pathname === "/dashboard") && (
              <Link
                to="/transcript"
                className={`${buttonClasses} bg-gray-700 hover:bg-gray-600`}
              >
                Transcript
              </Link>
            )}

            <a
              href="https://x.com/garberchov/status/1866214878731747735"
              target="_blank"
              rel="noopener noreferrer"
              className={`${buttonClasses} bg-amber-500 hover:bg-amber-600`}
            >
              Manifesto
            </a>

            <button
              onClick={handleExtensionDownload}
              className={`${buttonClasses} bg-blue-500 hover:bg-blue-600`}
            >
              Download Extension
            </button>

            {session ? (
              <>
                <button
                  onClick={onChangePasswordClick}
                  className={`${buttonClasses} bg-green-500 hover:bg-green-600`}
                >
                  Change Password
                </button>
                <button
                  onClick={handleSignOut}
                  className={`${buttonClasses} bg-indigo-500 hover:bg-indigo-600`}
                >
                  Sign Out
                </button>
                <Link
                  to="/plans"
                  className={`${buttonClasses} bg-purple-500 hover:bg-purple-600`}
                >
                  Plans
                </Link>
              </>
            ) : (
              <>
                <Link
                  to="/login"
                  className={`${buttonClasses} bg-slate-600 hover:bg-slate-700`}
                >
                  Sign In
                </Link>
                <Link
                  to="/login"
                  className={`${buttonClasses} bg-indigo-500 hover:bg-indigo-600`}
                >
                  Sign Up
                </Link>
                <Link
                  to="/plans"
                  className={`${buttonClasses} bg-purple-500 hover:bg-purple-600`}
                >
                  Plans
                </Link>
              </>
            )}
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
