import React from "react";

const SubscriptionRequired = ({ onClose, user, onContinueFree }) => {
  const handleSubscribe = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/create-checkout-session`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: user.id,
          }),
        }
      );

      const { url } = await response.json();
      window.location.href = url;
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleContinueFree = () => {
    onClose();
    onContinueFree();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-gray-800 p-8 rounded-lg max-w-md w-full mx-4">
        <h2 className="text-2xl font-bold text-white mb-4">
          Project Limit Reached
        </h2>
        <p className="text-gray-300 mb-6">
          You've reached the limit of your free account. Upgrade to our Student
          Plan to unlock unlimited projects and advanced features.
        </p>
        <div className="space-y-4">
          <button
            onClick={handleSubscribe}
            className="w-full bg-indigo-600 text-white py-2 px-4 rounded hover:bg-indigo-700 transition duration-200"
          >
            Upgrade Now - $29/month
          </button>
          <button
            onClick={handleContinueFree}
            className="w-full bg-gray-600 text-white py-2 px-4 rounded hover:bg-gray-700 transition duration-200"
          >
            Maybe Later
          </button>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionRequired;
