import React, { useState, useEffect } from "react";
import { supabase } from "../supabaseClient";
import Header from "./Header";
import posthog from "posthog-js";

const Auth = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [supabaseInitialized, setSupabaseInitialized] = useState(false);

  useEffect(() => {
    if (supabase) {
      setSupabaseInitialized(true);
    }
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      posthog.capture("login_attempted", { email });
      console.log("Attempting login with:", { email, password });
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });
      if (error) {
        console.error("Login error:", error);
        console.error("Error details:", error.message, error.status);
        throw error;
      }
      console.log("Login successful:", data);
    } catch (error) {
      posthog.capture("login_error", { error: error.message });
      console.error("Caught error:", error);
      console.error("Error stack:", error.stack);
      alert(error.message || "An error occurred during login");
    } finally {
      setLoading(false);
    }
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const { data, error } = await supabase.auth.signUp({ email, password });
      if (error) throw error;
      posthog.capture("user_signed_up", { email });
      if (data && data.user) {
        console.log("User signed up successfully:", data.user);
        console.log("Checking existing data for user:", data.user.id);
        console.log(
          "Full URL:",
          `${process.env.REACT_APP_API_URL}/api/check_existing_data?userId=${data.user.id}`
        );

        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/check_existing_data?userId=${data.user.id}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          console.log("Response status:", response.status);

          if (!response.ok) {
            const errorText = await response.text();
            console.error("Error response:", errorText);
            throw new Error(
              `HTTP error! status: ${response.status}, body: ${errorText}`
            );
          }

          const result = await response.json();
          console.log("Existing data check result:", result);
        } catch (checkError) {
          console.error("Error checking existing data:", checkError);
          console.error("Error details:", checkError.message);
          posthog.capture("existing_data_check_error", {
            error: checkError.message,
          });
        }
      } else {
        console.log("Sign up successful, but no user data returned");
      }
    } catch (error) {
      console.error("Error signing up:", error.message);
      posthog.capture("signup_error", { error: error.message });
      alert(error.message || "An error occurred during sign up");
    } finally {
      setLoading(false);
    }
  };

  if (!supabaseInitialized) {
    return <div>Initializing...</div>;
  }

  return (
    <>
      <Header />
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-gray-900 via-purple-900 to-violet-600">
        <div className="w-full max-w-md bg-gray-800 bg-opacity-80 backdrop-filter backdrop-blur-xl rounded-2xl shadow-2xl p-8">
          <h2 className="text-4xl font-bold mb-6 text-center text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-gray-400">
            Heretic.School
          </h2>
          <form className="space-y-6">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-300 mb-1"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                required
                className="w-full px-4 py-3 bg-gray-700 bg-opacity-50 border border-gray-600 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-300"
              />
            </div>
            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-300 mb-1"
              >
                Password
              </label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter your password"
                required
                className="w-full px-4 py-3 bg-gray-700 bg-opacity-50 border border-gray-600 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-300"
              />
            </div>
            <button
              onClick={handleLogin}
              disabled={loading}
              className="w-full px-6 py-3 bg-gradient-to-r from-blue-500 to-gray-400 text-white font-bold rounded-lg hover:from-blue-600 hover:to-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-800 transition duration-300 transform hover:scale-105"
            >
              {loading ? "Loading..." : "Login"}
            </button>
            <button
              onClick={handleSignUp}
              disabled={loading}
              className="w-full px-6 py-3 bg-gradient-to-r from-green-500 to-gray-400 text-white font-bold rounded-lg hover:from-green-600 hover:to-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 focus:ring-offset-gray-800 transition duration-300 transform hover:scale-105"
            >
              {loading ? "Loading..." : "Sign Up"}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Auth;
