import { useEffect } from "react";
import posthog from "posthog-js";

const ExtensionDownload = () => {
  useEffect(() => {
    // Capture the event before redirecting
    posthog.capture("extension_download_clicked", {
      url: "https://chromewebstore.google.com/detail/hereticschool-chrome-exte/odlfeaecpimnkgmeigjokbhemhbppage",
    });

    // Set a small timeout to ensure the event is captured before redirecting
    setTimeout(() => {
      window.location.href =
        "https://chromewebstore.google.com/detail/hereticschool-chrome-exte/odlfeaecpimnkgmeigjokbhemhbppage";
    }, 100);
  }, []);

  return null;
};

export default ExtensionDownload;
