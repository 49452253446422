import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import posthog from "posthog-js";

const Form = ({ user }) => {
  const [formData, setFormData] = useState({
    age: "",
    dreamjob: "",
    biggestPersonalInterest: "",
  });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkExistingData = async () => {
      try {
        console.log("Checking existing data for user:", user.id);
        console.log(
          "Full URL:",
          `${process.env.REACT_APP_API_URL}/api/check_existing_data?userId=${user.id}`
        );

        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/check_existing_data?userId=${user.id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        console.log("Response status:", response.status);

        if (!response.ok) {
          const errorText = await response.text();
          console.error("Error response:", errorText);
          throw new Error(
            `HTTP error! status: ${response.status}, body: ${errorText}`
          );
        }

        const data = await response.json();
        console.log("Existing data check result:", data);
        if (data.exists) {
          navigate("/graph", { state: { submissionId: data.submissionId } });
        }
      } catch (error) {
        console.error("Error checking existing data:", error);
        console.error("Error details:", error.message);
        setError("Failed to check existing data. Please try again later.");
      }
    };

    if (user && user.id) {
      checkExistingData();
    }
  }, [navigate, user]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);
    try {
      posthog.capture("form_submitted", formData);

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/get_start_recommendation`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ...formData, userId: user.id }),
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      alert(
        "If you have not already installed the extension, please do so now and refresh once you have. Your graph will not work without it."
      );
      navigate("/graph", { state: { recommendation: data } });
    } catch (error) {
      console.error("Error details:", error);
      setError("Failed to submit form. Please try again later.");
      posthog.capture("form_submission_error", { error: error.message });
    } finally {
      setIsLoading(false);
    }
  };

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <>
      <Header />
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-gray-900 via-purple-900 to-violet-600 p-4">
        <div className="w-full max-w-2xl bg-gray-800 bg-opacity-80 backdrop-blur-xl rounded-2xl shadow-2xl p-8">
          <h2 className="text-4xl font-bold mb-6 text-center text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-gray-400">
            Heretic.School
          </h2>
          <p className="text-gray-300 mb-6 text-center">
            Please provide detailed information to help us tailor your learning
            experience. The more specific you are, the better we can customize
            your journey.
          </p>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label
                htmlFor="age"
                className="block text-sm font-medium text-gray-300 mb-1"
              >
                Age
              </label>
              <input
                type="number"
                id="age"
                name="age"
                value={formData.age}
                onChange={handleChange}
                placeholder="Enter your age"
                required
                className="w-full px-4 py-3 bg-gray-700 bg-opacity-50 border border-gray-600 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-300 shadow-glow"
              />
              <p className="mt-1 text-sm text-gray-400">
                Your age helps us provide age-appropriate content and
                recommendations.
              </p>
            </div>
            <div>
              <label
                htmlFor="dreamjob"
                className="block text-sm font-medium text-gray-300 mb-1"
              >
                Dream Job
              </label>
              <textarea
                id="dreamjob"
                name="dreamjob"
                value={formData.dreamjob}
                onChange={handleChange}
                placeholder="Startup founder in the climate tech space, focusing on developing innovative carbon capture technologies. I'm passionate about combining entrepreneurship with environmental impact to address the urgent challenge of climate change."
                required
                rows={4}
                className="w-full px-4 py-3 bg-gray-700 bg-opacity-50 border border-gray-600 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-300 shadow-glow"
              />
              <p className="mt-1 text-sm text-gray-400">
                Understanding your career aspirations helps us tailor content to
                support your professional goals.
              </p>
            </div>
            <div>
              <label
                htmlFor="biggestPersonalInterest"
                className="block text-sm font-medium text-gray-300 mb-1"
              >
                Biggest Personal Interest
              </label>
              <textarea
                id="biggestPersonalInterest"
                name="biggestPersonalInterest"
                value={formData.biggestPersonalInterest}
                onChange={handleChange}
                placeholder="The logistics of the Eastern Front in World War II, particularly how supply chains and resource management impacted military strategies. I'm fascinated by how logistical challenges shaped the outcome of major battles and the war as a whole."
                required
                rows={4}
                className="w-full px-4 py-3 bg-gray-700 bg-opacity-50 border border-gray-600 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-300 shadow-glow"
              />
              <p className="mt-1 text-sm text-gray-400">
                Your personal interests help us create a more engaging and
                motivating learning experience for you.
              </p>
            </div>
            <button
              type="submit"
              disabled={isLoading}
              className="w-full px-6 py-3 bg-gradient-to-r from-blue-500 to-gray-400 text-white font-bold rounded-lg hover:from-blue-600 hover:to-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-800 transition duration-300 shadow-lg transform hover:scale-105 disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center"
            >
              {isLoading ? (
                <>
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Processing...
                </>
              ) : (
                "Get Started"
              )}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Form;
