import React, {
  useEffect,
  useCallback,
  useState,
  useRef,
  useMemo,
} from "react";
import ReactFlow, {
  useNodesState,
  useEdgesState,
  Background,
  Controls,
  Handle,
  addEdge,
  ReactFlowProvider,
} from "reactflow";
import dagre from "dagre";
import "reactflow/dist/style.css";
import { useLocation } from "react-router-dom";
import Header from "./components/Header";
import MarkdownIt from "markdown-it";
import MdEditor from "react-markdown-editor-lite";
import "react-markdown-editor-lite/lib/index.css";
import { ErrorBoundary } from "react-error-boundary";
import posthog from "posthog-js";
import LoadingSpinner from "./components/LoadingSpinner";
import ChangePasswordPopup from "./components/ChangePasswordPopup";
import SubscriptionRequired from "./components/SubscriptionRequired";

const mdParser = new MarkdownIt();

// BACKEND_URL FROM .ENV
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

console.log("BACKEND_URL:", BACKEND_URL);

const isYouTubeUrl = (url) => {
  return url.includes("youtube.com/watch") || url.includes("youtu.be/");
};

const CustomNode = ({ id, data, user }) => {
  const [width, setWidth] = useState(400);
  const nodeRef = useRef(null);
  const resizeRef = useRef(null);

  useEffect(() => {
    const node = nodeRef.current;
    const resizeHandle = resizeRef.current;
    let startX;
    let startWidth;

    const onMouseDown = (e) => {
      e.preventDefault();
      e.stopPropagation();
      startX = e.clientX;
      startWidth = node.offsetWidth;
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
    };

    const onMouseMove = (e) => {
      const dx = e.clientX - startX;
      const newWidth = Math.max(200, startWidth + dx);
      setWidth(newWidth);
    };

    const onMouseUp = () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };

    resizeHandle.addEventListener("mousedown", onMouseDown);

    return () => {
      resizeHandle.removeEventListener("mousedown", onMouseDown);
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };
  }, []);

  const handleLinkClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (data?.url) {
        console.log("Sending openUrlInExtension message:", data.url, id);
        const isYouTube = isYouTubeUrl(data.url);
        window.postMessage(
          {
            type: "HERETIC_OPEN_URL",
            action: "openUrlInExtension",
            url: data.url,
            submissionId: id,
            userId: user?.id,
            contentType: isYouTube ? "video" : "article",
          },
          "*"
        );
      } else {
        console.error("Missing url:", data);
        setTimeout(() => {
          alert(
            "Please ensure you have installed Heretic.School's Chrome Extension, or contact us andrew@heretic.school"
          );
        }, 1000);
      }
    },
    [data, id, user?.id]
  );

  if (!data || typeof data !== "object") {
    console.error("Invalid data for CustomNode:", data);
    return null;
  }

  return (
    <div
      ref={nodeRef}
      className="p-4 rounded-lg bg-gradient-to-r from-gray-800 to-gray-700 border-2 border-blue-500 text-white shadow-lg backdrop-filter backdrop-blur-sm flex flex-col items-center justify-center relative"
      style={{ width: `${width}px`, minWidth: "200px" }}
      onClick={() => data.onExpand && data.onExpand(id)}
    >
      {data.category && (
        <div className="flex flex-col items-center mb-2">
          <span className="text-xs font-semibold bg-blue-500 text-white px-2 py-1 rounded-full">
            {data.category}
          </span>
          {data.progress && (
            <div className="mt-1 text-xs text-gray-300">
              <span>Unit {data.progress.current_level + 1}</span>
              <span className="mx-1">•</span>
              <span>
                {data.progress.projects_completed} /{" "}
                {data.progress.projects_required} Projects
              </span>
              <div className="w-full bg-gray-700 rounded-full h-1 mt-1">
                <div
                  className="bg-blue-500 rounded-full h-1"
                  style={{
                    width: `${
                      ((data.progress.projects_completed %
                        data.progress.projects_required) /
                        data.progress.projects_required) *
                      100
                    }%`,
                  }}
                />
              </div>
            </div>
          )}
        </div>
      )}
      <strong className="text-lg text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-teal-300 text-center">
        {data.label || data.title || "Untitled"}
      </strong>
      {data.expanded && (
        <>
          <p className="mt-2 text-center">
            <a
              href={data.url || "#"}
              onClick={handleLinkClick}
              className="text-blue-400 hover:text-blue-300 underline"
            >
              {data.url || "No URL provided"}
            </a>
          </p>
          <p className="mt-2 text-gray-300 text-center">
            {data.description || "No description available"}
          </p>
        </>
      )}
      <div
        ref={resizeRef}
        className="absolute right-0 top-0 bottom-0 w-2 cursor-ew-resize bg-blue-500 opacity-50 hover:opacity-100"
      />
      <Handle type="source" position="bottom" id={`${id}-source`} />
      <Handle type="target" position="top" id={`${id}-target`} />
    </div>
  );
};

const ProjectNode = ({ id, data, userId }) => {
  const [width, setWidth] = useState(400);
  const nodeRef = useRef(null);
  const resizeRef = useRef(null);
  const [isRerollDialogOpen, setIsRerollDialogOpen] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [activeTab, setActiveTab] = useState("text");
  const [isRecording, setIsRecording] = useState(false);
  const [processingState, setProcessingState] = useState("idle");
  const mediaRecorder = useRef(null);
  const audioChunks = useRef([]);
  const [yapSessionId, setYapSessionId] = useState(null);
  const [conversationHistory, setConversationHistory] = useState([]);

  useEffect(() => {
    const node = nodeRef.current;
    const resizeHandle = resizeRef.current;
    let startX;
    let startWidth;

    const onMouseDown = (e) => {
      e.preventDefault();
      e.stopPropagation();
      startX = e.clientX;
      startWidth = node.offsetWidth;
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
    };

    const onMouseMove = (e) => {
      const dx = e.clientX - startX;
      const newWidth = Math.max(200, startWidth + dx);
      setWidth(newWidth);
    };

    const onMouseUp = () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };

    resizeHandle.addEventListener("mousedown", onMouseDown);

    return () => {
      resizeHandle.removeEventListener("mousedown", onMouseDown);
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };
  }, []);

  const [submission, setSubmission] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleEditorChange = ({ text }) => {
    setSubmission(text);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      let imageData = null;
      if (selectedImage) {
        const reader = new FileReader();
        imageData = await new Promise((resolve, reject) => {
          reader.onload = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(selectedImage);
        });
      }

      const response = await data.onSubmit(submission, id, imageData);

      if (response.error) {
        throw new Error(response.error);
      }

      // Clear form after successful submission
      setSubmission("");
      setSelectedImage(null);
      setImagePreview(null);
    } catch (error) {
      console.error("Error submitting project:", error);
      //   alert("Failed to submit project. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleReroll = (e) => {
    e.stopPropagation(); // Prevent the click event from bubbling up to the node
    setIsRerollDialogOpen(true);
  };

  const submitReroll = (e) => {
    e.stopPropagation(); // Prevent the click event from bubbling up to the node
    if (data.onReroll) {
      data.onReroll(id, rejectReason);
      setIsRerollDialogOpen(false);
      setRejectReason("");
    } else {
      console.error("onReroll function is not defined");
    }
  };

  const project = data.project || {};
  const { title, description, instructions, category } = project;

  const handleImageSelect = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Validate file type and size
      const validTypes = ["image/jpeg", "image/png", "image/gif"];
      const maxSize = 5 * 1024 * 1024; // 5MB

      if (!validTypes.includes(file.type)) {
        alert("Please select a valid image file (JPEG, PNG, or GIF)");
        return;
      }

      if (file.size > maxSize) {
        alert("Image must be less than 5MB");
        return;
      }

      setSelectedImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const initializeYapMode = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/start_yap_session`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            type: "project",
            context: data.project,
            userId: userId,
            parentId: id,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to start Yap session");
      }

      const result = await response.json();
      if (result.session_id) {
        setYapSessionId(result.session_id);
        startRecording(result.session_id);
      }
    } catch (error) {
      console.error("Error starting voice chat:", error);
      setProcessingState("error");
    }
  };

  const startRecording = async (sessionId) => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorder.current = new MediaRecorder(stream);
      audioChunks.current = [];

      mediaRecorder.current.ondataavailable = (event) => {
        audioChunks.current.push(event.data);
      };

      mediaRecorder.current.onstop = () => {
        processRecording(
          new Blob(audioChunks.current, { type: "audio/wav" }),
          sessionId
        );
      };

      mediaRecorder.current.start();
      setIsRecording(true);
    } catch (error) {
      console.error("Error starting recording:", error);
      setProcessingState("error");
    }
  };

  const stopRecording = () => {
    if (mediaRecorder.current && isRecording) {
      mediaRecorder.current.stop();
      setIsRecording(false);
      mediaRecorder.current.stream.getTracks().forEach((track) => track.stop());
    }
  };

  const processRecording = async (audioBlob, sessionId) => {
    try {
      if (!sessionId) {
        throw new Error("No active session ID");
      }

      setProcessingState("processing");

      const reader = new FileReader();
      reader.readAsDataURL(audioBlob);
      reader.onloadend = async () => {
        try {
          const base64Audio = reader.result.split(",")[1];

          const response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/api/process_yap`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                session_id: sessionId,
                audio: base64Audio,
                conversation: conversationHistory,
              }),
            }
          );

          if (!response.ok) {
            setProcessingState("error");
            throw new Error("Failed to process recording");
          }

          const result = await response.json();

          // Update conversation history with the new messages
          const updatedHistory = [
            ...conversationHistory,
            { role: "user", content: result.transcript },
            { role: "assistant", content: result.text },
          ];
          setConversationHistory(updatedHistory);

          // Send transcript update to content script
          window.postMessage(
            {
              type: "UPDATE_TRANSCRIPT",
              messages: updatedHistory,
            },
            "*"
          );

          const audio = new Audio(`data:audio/mp3;base64,${result.audio}`);

          await new Promise((resolve) => {
            audio.onended = resolve;
            audio.play();
          });

          if (result.completion_status && result.feedback) {
            setProcessingState("complete");
            try {
              await data.onSubmit(result.transcript, id, null, true);
            } catch (submitError) {
              console.error("Error submitting project:", submitError);
            }
          } else {
            setProcessingState("idle");
          }
        } catch (error) {
          console.error("Error processing recording:", error);
          setProcessingState("error");
        }
      };
    } catch (error) {
      console.error("Error in processRecording:", error);
      setProcessingState("error");
    }
  };

  const handleVoiceChatClick = () => {
    if (isRecording) {
      stopRecording();
    } else if (yapSessionId) {
      startRecording(yapSessionId);
    } else {
      initializeYapMode();
    }
  };

  return (
    <div
      ref={nodeRef}
      className="bg-gradient-to-r from-purple-800 to-blue-950 rounded-lg p-6 shadow-xl border border-pink-500 text-white backdrop-filter backdrop-blur-sm relative"
      style={{ width: `${width}px`, minWidth: "200px" }}
    >
      {category && (
        <div className="flex flex-col items-center mb-2">
          <span className="text-xs font-semibold bg-blue-500 text-white px-2 py-1 rounded-full">
            {category}
          </span>
          {data.progress && (
            <div className="mt-1 text-xs text-gray-300">
              <span>Unit {data.progress.current_level + 1}</span>
              <span className="mx-1">•</span>
              <span>
                {data.progress.projects_completed} /{" "}
                {data.progress.projects_required} Projects
              </span>
              <div className="w-full bg-gray-700 rounded-full h-1 mt-1">
                <div
                  className="bg-pink-500 rounded-full h-1"
                  style={{
                    width: `${
                      ((data.progress.projects_completed %
                        data.progress.projects_required) /
                        data.progress.projects_required) *
                      100
                    }%`,
                  }}
                />
              </div>
            </div>
          )}
        </div>
      )}
      <h3
        className="text-2xl font-bold mb-4 text-transparent bg-clip-text bg-gradient-to-r from-pink-400 to-purple-300 cursor-pointer"
        onClick={() => data.onExpand && data.onExpand(id)}
      >
        {title || "Untitled Project"}
      </h3>
      {data.expanded && (
        <>
          <p className="mb-4 text-gray-300 text-sm">
            {description || "No description available"}
          </p>
          <div className="mb-4">
            <h4 className="text-lg font-semibold mb-2 text-pink-300">
              Instructions:
            </h4>
            <pre className="text-gray-300 text-sm whitespace-pre-wrap font-sans">
              {instructions || "No instructions available"}
            </pre>
          </div>
          {(!data.feedback || !data.feedback.feedback) && (
            <button
              onClick={handleReroll}
              className="mt-4 w-full px-4 py-2 bg-gradient-to-r from-yellow-500 to-orange-500 text-white font-bold rounded-lg hover:from-yellow-600 hover:to-orange-600 transition duration-300 transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-opacity-50"
            >
              Re-roll Project
            </button>
          )}
          {!data.feedback || !data.feedback.feedback ? (
            <div className="mt-6">
              {/* Tabs */}
              <div className="border-b border-gray-700 mb-4">
                <div className="flex">
                  <button
                    type="button"
                    className={`py-2 px-4 mr-2 rounded-t transition-colors duration-200 ${
                      activeTab === "text"
                        ? "bg-pink-500 text-white"
                        : "text-gray-400 hover:text-white"
                    }`}
                    onClick={() => setActiveTab("text")}
                  >
                    Text Editor
                  </button>
                  <button
                    type="button"
                    className={`py-2 px-4 flex items-center transition-colors duration-200 ${
                      activeTab === "voice"
                        ? "bg-purple-500 text-white"
                        : "text-gray-400 hover:text-white"
                    }`}
                    onClick={() => setActiveTab("voice")}
                  >
                    <svg
                      className="w-4 h-4 mr-2"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z"
                      />
                    </svg>
                    Voice Chat
                  </button>
                </div>
              </div>

              {/* Tab Content */}
              <div className="tab-content">
                {/* Text Editor Tab */}
                {activeTab === "text" && (
                  <form onSubmit={handleSubmit}>
                    <MdEditor
                      value={submission}
                      style={{ height: "200px" }}
                      renderHTML={(text) => mdParser.render(text)}
                      onChange={handleEditorChange}
                      className="mb-4 bg-purple-900 bg-opacity-50 rounded-lg overflow-hidden"
                      view={{ menu: true, md: true, html: false }}
                    />
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-gray-300 mb-2">
                        Add Image (optional)
                      </label>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageSelect}
                        className="block w-full text-sm text-gray-300
                          file:mr-4 file:py-2 file:px-4
                          file:rounded-full file:border-0
                          file:text-sm file:font-semibold
                          file:bg-blue-500 file:text-white
                          hover:file:bg-blue-600"
                      />
                      {imagePreview && (
                        <div className="mt-2">
                          <img
                            src={imagePreview}
                            alt="Preview"
                            className="max-w-xs rounded-lg"
                          />
                          <button
                            type="button"
                            onClick={() => {
                              setSelectedImage(null);
                              setImagePreview(null);
                            }}
                            className="mt-2 text-red-400 hover:text-red-300"
                          >
                            Remove Image
                          </button>
                        </div>
                      )}
                    </div>
                    <button
                      type="submit"
                      className="w-full px-4 py-2 bg-gradient-to-r from-pink-500 to-purple-500 text-white font-bold rounded-lg hover:from-pink-600 hover:to-purple-600 transition duration-300 transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-opacity-50"
                      disabled={isLoading}
                    >
                      Submit Project
                    </button>
                  </form>
                )}

                {/* Voice Chat Tab */}
                {activeTab === "voice" && (
                  <div>
                    <div className="yap-status text-gray-300 mb-2">
                      {isRecording ? (
                        "Recording... Click stop when finished speaking"
                      ) : processingState === "processing" ? (
                        <div className="flex items-center justify-center">
                          <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-purple-500 mr-2" />
                          Processing...
                        </div>
                      ) : processingState === "complete" ? (
                        <div className="text-green-400">
                          Project completed - Refresh to see your feedback and
                          new content!
                        </div>
                      ) : (
                        "Complete your project by talking to your personal AI Tutor"
                      )}
                    </div>
                    {/* Transcript Display Box */}
                    {conversationHistory.length > 0 && (
                      <div className="mb-4 p-3 bg-gray-800 rounded-lg border border-purple-500 max-h-48 overflow-y-auto">
                        <h4 className="text-sm font-semibold text-purple-300 mb-2">
                          Conversation Transcript:
                        </h4>
                        {conversationHistory.map((msg, idx) => (
                          <div key={idx} className="mb-2 last:mb-0">
                            <span
                              className={`font-semibold ${
                                msg.role === "user"
                                  ? "text-blue-300"
                                  : "text-green-300"
                              }`}
                            >
                              {msg.role === "user" ? "You" : "AI Tutor"}:
                            </span>
                            <span className="text-gray-300 ml-2">
                              {msg.content}
                            </span>
                          </div>
                        ))}
                      </div>
                    )}
                    {processingState !== "complete" && (
                      <button
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleVoiceChatClick();
                        }}
                        className={`w-full ${
                          isRecording
                            ? "bg-red-500 hover:bg-red-600"
                            : "bg-purple-500 hover:bg-purple-600"
                        } text-white px-4 py-2 rounded flex items-center justify-center`}
                        disabled={processingState === "processing"}
                      >
                        {isRecording ? "Stop Recording" : "Start Voice Chat"}
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="mt-4 bg-purple-900 bg-opacity-50 rounded-lg p-4">
              <h4 className="text-lg font-semibold mb-2 text-transparent bg-clip-text bg-gradient-to-r from-pink-400 to-purple-300">
                Feedback:
              </h4>
              <p className="mb-3 text-gray-300 text-sm">
                {data.feedback.feedback}
              </p>
              <p className="mb-2 text-green-400 text-sm">
                <strong>Strength:</strong> {data.feedback.strength}
              </p>
              <p className="mb-2 text-yellow-400 text-sm">
                <strong>Area for Improvement:</strong> {data.feedback.weakness}
              </p>
            </div>
          )}
        </>
      )}
      <div
        ref={resizeRef}
        className="absolute right-0 top-0 bottom-0 w-2 cursor-ew-resize bg-pink-500 opacity-50 hover:opacity-100"
      />
      <Handle
        type="source"
        position="bottom"
        id={`${id}-source`}
        className="w-3 h-3 bg-pink-500"
      />
      <Handle
        type="target"
        position="top"
        id={`${id}-target`}
        className="w-3 h-3 bg-pink-500"
      />
      {isRerollDialogOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
          onClick={(e) => e.stopPropagation()}
        >
          <div
            className="bg-white p-6 rounded-lg"
            onClick={(e) => e.stopPropagation()}
          >
            <h2 className="text-xl font-bold mb-4 text-gray-900">
              Re-roll Project
            </h2>
            <p className="mb-4 text-gray-700">
              Please provide a reason for re-rolling this project:
            </p>
            <textarea
              className="w-full p-2 border rounded text-gray-900"
              value={rejectReason}
              onChange={(e) => setRejectReason(e.target.value)}
              rows={4}
              onClick={(e) => e.stopPropagation()}
            />
            <div className="mt-4 flex justify-end">
              <button
                className="mr-2 px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsRerollDialogOpen(false);
                }}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                onClick={submitReroll}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const UpdateInfoPopup = ({
  onClose,
  onSubmit,
  currentDreamJob,
  currentPersonalInterest,
}) => {
  const [dreamJob, setDreamJob] = useState(currentDreamJob);
  const [personalInterest, setPersonalInterest] = useState(
    currentPersonalInterest
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(dreamJob, personalInterest);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg">
        <h2 className="text-xl font-bold mb-4">Update Your Information</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="dreamJob"
            >
              Dream Job
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="dreamJob"
              type="text"
              value={dreamJob}
              onChange={(e) => setDreamJob(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="personalInterest"
            >
              Personal Interest
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="personalInterest"
              type="text"
              value={personalInterest}
              onChange={(e) => setPersonalInterest(e.target.value)}
            />
          </div>
          <div className="flex justify-end">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2"
              type="submit"
            >
              Update
            </button>
            <button
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="button"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const CentralNode = ({ data, onUpdateClick }) => {
  if (!data) return null;

  return (
    <div
      className="p-4 rounded-lg bg-gradient-to-r from-purple-600 to-indigo-600 border-2 border-white text-white shadow-lg backdrop-filter backdrop-blur-sm max-w-sm flex flex-col items-center justify-center cursor-pointer"
      onClick={onUpdateClick}
    >
      <div className="mb-2 text-center">
        <strong className="text-sm text-pink-300">Dream Job:</strong>
        <p className="text-lg font-bold">{data.dreamjob || "Not set"}</p>
      </div>
      <div className="text-center">
        <strong className="text-sm text-green-300">Biggest Interest:</strong>
        <p className="text-lg font-bold">
          {data.biggest_personal_interest || "Not set"}
        </p>
      </div>
      <div className="mt-2 text-xs text-gray-300">Click to update</div>
      <Handle type="source" position="bottom" id="central-source" />
    </div>
  );
};

const NewBranchNode = ({ data }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");

  const handleConfirm = () => {
    if (!selectedCategory) return;
    setIsLoading(true);
    data
      .onConfirm(selectedCategory)
      .then(() => setIsLoading(false))
      .catch((error) => {
        console.error("Error confirming new branch:", error);
        setIsLoading(false);
      });
  };

  return (
    <div className="p-4 rounded-lg bg-gradient-to-r from-yellow-400 to-orange-500 border-2 border-white text-white shadow-lg backdrop-filter backdrop-blur-sm max-w-sm flex flex-col items-center justify-center">
      <h3 className="text-lg font-bold mb-2">Add a New Branch?</h3>
      <p className="mb-4">Choose a category to explore:</p>
      <select
        className="w-full p-2 mb-4 text-gray-700 bg-white rounded-md"
        onChange={(e) => setSelectedCategory(e.target.value)}
        value={selectedCategory}
        disabled={isLoading}
      >
        <option value="">Select a category</option>
        {data.availableCategories.map((category) => (
          <option key={category} value={category}>
            {category}
          </option>
        ))}
      </select>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-300"
          onClick={handleConfirm}
          disabled={!selectedCategory}
        >
          Confirm
        </button>
      )}
      <Handle type="target" position="top" id="new-branch-target" />
    </div>
  );
};

const ErrorFallback = ({ error }) => {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
    </div>
  );
};

const getLayoutedElements = (nodes, edges) => {
  if (!nodes.length) return { nodes: [], edges: [] };

  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  const nodeWidth = 400;
  const nodeHeight = 150;

  dagreGraph.setGraph({
    rankdir: "TB",
    ranksep: 200,
    nodesep: 200,
    marginx: 50,
    marginy: 50,
  });

  // Add nodes to dagre graph
  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  // Add edges to dagre graph
  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  // Calculate layout
  dagre.layout(dagreGraph);

  // Apply layout to nodes
  const layoutedNodes = nodes.map((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    return {
      ...node,
      position: {
        x: nodeWithPosition.x - nodeWidth / 2,
        y: nodeWithPosition.y - nodeHeight / 2,
      },
    };
  });

  return { nodes: layoutedNodes, edges };
};

// Add this new component
const BranchProgressCard = ({ category, progress }) => {
  const nextLevelRequirement = progress.projects_required;
  const currentProgress =
    progress.projects_completed % progress.projects_required;

  return (
    <div className="bg-gray-800 border border-blue-500 rounded-lg p-3 text-white text-sm mb-2">
      <div className="font-bold text-blue-400 mb-1 capitalize">{category}</div>
      <div className="text-gray-300">
        <div className="flex justify-between mb-1">
          <span>Unit {progress.current_level + 1}</span>
          <span>
            {currentProgress} / {nextLevelRequirement}
          </span>
        </div>
        <div className="w-full bg-gray-700 rounded-full h-1">
          <div
            className="bg-blue-500 rounded-full h-1 transition-all duration-300"
            style={{
              width: `${(currentProgress / nextLevelRequirement) * 100}%`,
            }}
          />
        </div>
        <div className="text-xs mt-1 text-gray-400">
          Total completed: {progress.projects_completed}
        </div>
      </div>
    </div>
  );
};

// First, move all the hooks and their related functions inside the Graph component
const Graph = ({ user, setError }) => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const location = useLocation();
  const data = location.state?.recommendation;
  const [submissionId, setSubmissionId] = useState(null);
  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const [centralNodeData, setCentralNodeData] = useState(null);
  const [showChangePasswordPopup, setShowChangePasswordPopup] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);

  // Ensure userId is available
  const userId = user?.id;

  // Log userId to verify it's being set
  //   console.log('User ID:', userId);

  // 1. Create a stable reference to fetchAndUpdateProject using useRef
  const fetchAndUpdateProjectRef = useRef(null);
  fetchAndUpdateProjectRef.current = async () => {
    try {
      const url = submissionId
        ? `${BACKEND_URL}/api/get_project?submissionId=${submissionId}&userId=${user.id}`
        : `${BACKEND_URL}/api/get_project?userId=${user.id}`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        credentials: "include",
      });

      if (!response.ok) {
        const data = await response.json();
        if (data.code === "subscription_required") {
          setShowSubscriptionModal(true);
          return;
        }
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (!data.nodes || !data.edges) {
        console.error("Invalid data structure received:", data);
        return;
      }

      const processedNodes = data.nodes.map((node) => ({
        ...node,
        data: {
          ...node.data,
          onExpand: onNodeClick,
          onSubmit:
            node.type === "projectNode" ? handleSubmitProject : undefined,
          onUpdateClick:
            node.type === "centralNode" ? handleUpdateClick : undefined,
          onReroll:
            node.type === "projectNode" ? handleRerollProject : undefined,
        },
      }));

      const { nodes: layoutedNodes, edges: layoutedEdges } =
        getLayoutedElements(processedNodes, data.edges);

      setNodes(layoutedNodes);
      setEdges(layoutedEdges);

      const centralNode = layoutedNodes.find(
        (node) => node.id === "central-node"
      );
      if (centralNode) {
        setCentralNodeData(centralNode.data);
      }
    } catch (error) {
      console.error("Error fetching project data:", error);
      setError(error);
    }
  };

  // 1. Define base callbacks first
  const onNodeClick = useCallback(
    (nodeId) => {
      setNodes((nds) =>
        nds.map((n) => {
          if (n.id === nodeId) {
            return {
              ...n,
              data: {
                ...n.data,
                expanded: !n.data.expanded,
              },
            };
          }
          return n;
        })
      );
      posthog.capture("node_expanded", { nodeId });
    },
    [setNodes]
  );

  const handleUpdateClick = useCallback(() => {
    setShowUpdatePopup(true);
  }, []);

  // 2. Define handlers using the ref
  const handleSubmitProject = useCallback(
    async (
      submission,
      projectSubmissionId,
      imageData,
      isYapSubmission = false
    ) => {
      try {
        const url = `${BACKEND_URL}/api/submit_project`;
        const submitData = {
          projectSubmission: submission,
          submissionId: projectSubmissionId,
          userId: user.id,
          imageData: imageData,
          isYapSubmission: isYapSubmission, // Add this flag
        };

        const response = await fetch(url, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(submitData),
        });

        if (!response.ok)
          throw new Error(`HTTP error! status: ${response.status}`);
        const responseData = await response.json();
        if (responseData.status === "success") {
          // Only fetch and update if it's not a yap submission
          if (!isYapSubmission) {
            await fetchAndUpdateProjectRef.current();
          }
        }
      } catch (error) {
        console.error("Error submitting project:", error);
        setError(error);
      }
    },
    [user?.id, setError]
  );

  const handleRerollProject = useCallback(
    (projectId, rejectReason) => {
      fetch(`${BACKEND_URL}/api/reroll_project`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ projectId, rejectReason, userId: user.id }),
      })
        .then((response) => {
          if (!response.ok) {
            return response.json().then((errorData) => {
              throw new Error(
                errorData.error || `HTTP error! status: ${response.status}`
              );
            });
          }
          return response.json();
        })
        .then((data) => {
          if (data.status === "success") {
            fetchAndUpdateProjectRef.current();
          } else {
            throw new Error(data.error || "Failed to reroll project");
          }
        })
        .catch((error) => {
          console.error("Error rerolling project:", error);
          setError(error.message);
        });
    },
    [user?.id, setError]
  );

  // 3. Fix the loop issue in getBranchProgress
  const getBranchProgress = useCallback(() => {
    const branchProgress = {};
    const centralNode = nodes.find((node) => node.id === "central-node");
    if (!centralNode) return {};

    const mainBranches = edges.filter((edge) => edge.source === "central-node");
    const processedNodes = new Set();

    const processNodeData = (nodeData) => {
      try {
        let category = null;
        if (nodeData.project) {
          category =
            typeof nodeData.project === "string"
              ? JSON.parse(nodeData.project).category
              : nodeData.project.category;
        } else if (nodeData.recommendation) {
          const rec =
            typeof nodeData.recommendation === "string"
              ? JSON.parse(nodeData.recommendation)
              : nodeData.recommendation;
          category = rec.category;
        } else if (nodeData.category) {
          category = nodeData.category;
        }

        if (category && nodeData.progress && !branchProgress[category]) {
          branchProgress[category] = nodeData.progress;
        }
      } catch (error) {
        console.error("Error processing node data:", error);
      }
    };

    const findNextNode = (currentId) => {
      const nextEdge = edges.find((edge) => edge.source === currentId);
      return nextEdge?.target;
    };

    const findNodeById = (id) => nodes.find((n) => n.id === id);

    mainBranches.forEach((branch) => {
      let currentId = branch.target;
      const chainNodes = [];

      while (currentId && !processedNodes.has(currentId)) {
        const node = findNodeById(currentId);
        if (!node) break;

        chainNodes.push(node);
        processedNodes.add(currentId);
        currentId = findNextNode(currentId);
      }

      chainNodes.forEach((node) => {
        if (node.data) {
          processNodeData(node.data);
        }
      });
    });

    return branchProgress;
  }, [nodes, edges]);

  // Effects
  useEffect(() => {
    if (data?.submissionId) {
      setSubmissionId(data.submissionId);
    }
  }, [data]);

  useEffect(() => {
    if (user?.id) {
      fetchAndUpdateProjectRef.current();
    }
  }, [user?.id]);

  const onConnect = useCallback(
    (params) => {
      setEdges((eds) => addEdge(params, eds));
      posthog.capture("edge_connected", { params });
    },
    [setEdges]
  );

  const handleUpdateSubmit = useCallback(
    (newDreamJob, newPersonalInterest) => {
      fetch(`${BACKEND_URL}/api/update_user_info`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: user.id,
          dreamJob: newDreamJob,
          personalInterest: newPersonalInterest,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.message) {
            console.log(data.message);
            setNodes((prevNodes) =>
              prevNodes.map((node) => {
                if (
                  node.data &&
                  (node.data.dreamjob || node.data.biggest_personal_interest)
                ) {
                  return {
                    ...node,
                    data: {
                      ...node.data,
                      dreamjob: newDreamJob,
                      biggest_personal_interest: newPersonalInterest,
                    },
                  };
                }
                return node;
              })
            );
            setCentralNodeData((prevData) => ({
              ...prevData,
              dreamjob: newDreamJob,
              biggest_personal_interest: newPersonalInterest,
            }));
            setShowUpdatePopup(false);
            fetchAndUpdateProjectRef.current();
          } else {
            throw new Error(data.error || "Failed to update user info");
          }
        })
        .catch((error) => {
          console.error("Error updating user info:", error);
          setError(error);
        });
    },
    [user.id, setError, setNodes]
  );

  const handleChangePassword = useCallback(
    (currentPassword, newPassword) => {
      fetch(`${BACKEND_URL}/api/change_password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: user.id,
          currentPassword,
          newPassword,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.message) {
            console.log(data.message);
            alert("Password changed successfully");
            setShowChangePasswordPopup(false);
          } else {
            throw new Error(data.error || "Failed to change password");
          }
        })
        .catch((error) => {
          console.error("Error changing password:", error);
          alert(`Error changing password: ${error.message}`);
        });
    },
    [user.id]
  );

  const handleContinueFree = async () => {
    try {
      const url = submissionId
        ? `${BACKEND_URL}/api/get_project?submissionId=${submissionId}&userId=${user.id}&ignoreLimits=true`
        : `${BACKEND_URL}/api/get_project?userId=${user.id}&ignoreLimits=true`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      // Process and set nodes/edges as before
      const processedNodes = data.nodes.map((node) => ({
        ...node,
        data: {
          ...node.data,
          onExpand: onNodeClick,
          onSubmit:
            node.type === "projectNode" ? handleSubmitProject : undefined,
          onUpdateClick:
            node.type === "centralNode" ? handleUpdateClick : undefined,
          onReroll:
            node.type === "projectNode" ? handleRerollProject : undefined,
        },
      }));

      const { nodes: layoutedNodes, edges: layoutedEdges } =
        getLayoutedElements(processedNodes, data.edges);

      setNodes(layoutedNodes);
      setEdges(layoutedEdges);
    } catch (error) {
      console.error("Error loading graph data:", error);
      setError(error);
    }
  };

  const nodeTypes = useMemo(
    () => ({
      custom: (props) => <CustomNode {...props} user={user} />,
      projectNode: (props) => <ProjectNode {...props} userId={userId} />,
      centralNode: (props) => (
        <CentralNode {...props} onUpdateClick={handleUpdateClick} />
      ),
      newBranchNode: NewBranchNode,
    }),
    [handleUpdateClick, user, userId]
  );

  return (
    <div className="flex flex-col h-screen">
      <Header onChangePasswordClick={() => setShowChangePasswordPopup(true)} />
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <div className="flex-1 relative">
          <div className="absolute top-4 left-4 z-10 w-64 space-y-2 max-h-[calc(100vh-120px)] overflow-y-auto">
            {Object.entries(getBranchProgress()).map(([category, progress]) => (
              <BranchProgressCard
                key={category}
                category={category}
                progress={progress}
              />
            ))}
          </div>

          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onNodeClick={onNodeClick}
            nodeTypes={nodeTypes}
            fitView
            fitViewOptions={{ padding: 0.2, includeHiddenNodes: false }}
            defaultEdgeOptions={{ type: "straight" }}
            className="bg-gray-900"
          >
            <Background variant="dots" gap={12} size={1} color="#2a2a2a" />
            <Controls className="bg-gray-700 text-white" />
          </ReactFlow>

          {showUpdatePopup && centralNodeData && (
            <UpdateInfoPopup
              onClose={() => setShowUpdatePopup(false)}
              onSubmit={handleUpdateSubmit}
              currentDreamJob={centralNodeData.dreamjob}
              currentPersonalInterest={
                centralNodeData.biggest_personal_interest
              }
            />
          )}

          {showChangePasswordPopup && (
            <ChangePasswordPopup
              onClose={() => setShowChangePasswordPopup(false)}
              onSubmit={handleChangePassword}
            />
          )}

          {showSubscriptionModal && (
            <SubscriptionRequired
              onClose={() => setShowSubscriptionModal(false)}
              user={user}
              onContinueFree={handleContinueFree}
            />
          )}
        </div>
      </ErrorBoundary>
    </div>
  );
};

const SafeGraph = (props) => {
  const [error, setError] = useState(null);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <ReactFlowProvider>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Graph {...props} setError={setError} />
      </ErrorBoundary>
    </ReactFlowProvider>
  );
};

export default SafeGraph;
